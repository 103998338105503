import React from "react";
import { getDatabase, ref, set, push } from "firebase/database";
import { database } from "../firebase.config";
import { getAuth } from "firebase/auth";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { db } from "../firebase.config";
import { setDoc, doc, serverTimestamp, query } from "firebase/firestore";
import { onValue } from "firebase/database";
import { Checkbox } from "@material-tailwind/react";
import RoomForm from "./RoomForm";
// import data from '../data/data.json'
import axios from "axios";
import { useLocation } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleArrowRight } from '@fortawesome/free-solid-svg-icons';
import Navbar from "./Navbar/Navbar";
import CreateRoomSpinner from "./CreateRoomSpinner";

function RoomCreate() {
  const auth = getAuth();
  const database = getDatabase();
  const [roomCreate, setRoomCreate] = useState(false);
  const [roomTopics, setRoomTopics] = useState([]);
  const [all_questions, set_all_questions] = useState([]);
  const [room_questions, setroom_questions] = useState([]);
  const [data2, setdata2] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const { q } = location.state;
  const [questions, set_questions] = useState([]);
  const [random_id, setrandom_id] = useState(0);
  const [ques_details, setques_details] = useState([]);
  const [all_questions_loaded, setall_questions_loaded] = useState(false)
  const [selectedOption, setSelectedOption] = useState('low');
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    // create random room id
    setrandom_id(Math.floor(100000000 + Math.random() * 900000000));
    // const roomRef = ref(database, "rooms/" + random_id + "/topics");

    // const newroomRef = push(roomRef);
    // set(newroomRef, roomTopics);

    q.map((d) =>
      all_questions.push({
        id: d.question_id,
        title: d.title,
        level: d.level
      })
    );
    // Filtering 4 random questions
    const roomquesRef = ref(database, "rooms/" + random_id + "/questions");
    const apiquesRef = ref(database, "rooms/" + random_id + "/questions-api");
    // const dbRef = ref(getDatabase());
  }, []);

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value); // Update the selected option value
  };

  const createRoom = async (e) => {
    setRoomCreate(true);
  };

  const performAsyncLoop = async () => {
    // let i = 0;
    const len = all_questions.length;
    // while (i < 4) {
    //   let isValid = false;
    //   const len = all_questions.length;
    //   let ques_id = all_questions[Math.floor(Math.random() * len)];
    //   // console.log("QUES ID : ", ques_id)
    //   if (room_questions.includes(ques_id) === false && ques_id.level === selectedOption) {
    //     room_questions.push(ques_id);
    //     i++;
    //     isValid = true
    //   }

    //   // Call api to fetch ques details
    //   if (isValid === true){
    //   await axios
    //     .get(
    //       "https://ed-tech-backend-eight.vercel.app/problemsInfo/" + ques_id.id,
    //       {
    //         mode: "no-cors",
    //       }
    //     )
    //     .then((response) => {

    //         // set_all_questions
    //         ques_details.push(response.data);
    //       });
    //     if (room_questions.length === 4) {
    //       setall_questions_loaded(true)
    //     }
    //   }
    // }
    let arr = []
    for (let i = 0; i < len; i++) {
      if(all_questions[i].level === selectedOption){
        arr.push(all_questions[i]);
      }
    } 
    const l = arr.length;
    for (let i = 0; i < 4; i++) {
      // let ques_id = all_questions[i];
      let ind = Math.floor(Math.random() * l)
      let ques_id = arr[ind];
      arr.splice(ind,1);
      if (room_questions.includes(ques_id) === false) {
        room_questions.push(ques_id);
        await axios
        .get(
          "https://ed-tech-backend-eight.vercel.app/problemsInfo/" + ques_id.id,
          {
            mode: "no-cors",
          }
        )
        .then((response) => {
            // set_all_questions
            ques_details.push(response.data);
          });
        if (room_questions.length === 4) {
          setall_questions_loaded(true)
          break;
        }
      }
    }
  };

  const create = async (e) => {
    let programmingDSAWords = [
      "Algorithm", "Array", "Binary", "Search", "BigO", "Notation", "BFS", "Bubble", "Sort",
      "Cache", "Circular", "Queue", "Complexity", "Constant", "Time", "Data", "Structures",
      "DFS", "Divide", "Conquer", "Dynamic", "Fibonacci", "Graph", "Greedy", "Hash", "Heap",
      "Insertion", "Linear", "Linked", "List", "Merge", "MinMax", "Multithreading", "Node",
      "NPComplete", "OOP", "Priority", "Pseudocode", "Queue", "Quick", "Recursion", "RedBlack",
      "Tree", "Searching", "Selection", "Singly", "Sorting", "Space", "Stack", "String", "Suffix",
      "Time", "Topological", "Traversal", "Trie", "TwoPointer", "UnionFind", "Variable", "Vertex",
      "Binary", "Tree", "AVL", "Ternary", "Doubly", "HashMap", "HeapSort", "Interpolation",
      "BinaryHeap", "LIFO", "FILO", "GraphTraversal", "Radix", "BubbleSort", "ShellSort", "Heapify",
      "Pointer", "Bitmask", "Modulo", "Backtracking", "Branch", "Bound", "CircularLinkedList",
      "SegmentTree", "FenwickTree", "SparseTable", "DisjointSet", "Union", "Find", "DSU",
      "Recurrence", "MasterTheorem", "DynamicTable", "SparseMatrix", "GreedyAlgorithm", "Memoization",
      "PathFinding", "Dijkstra", "BellmanFord", "FloydWarshall", "Kruskal", "Prim", "ShortestPath",
      "SpanningTree", "CycleDetection", "TopologicalSorting", "Knapsack", "TravellingSalesman",
      "NQueen", "SubsetSum", "RodCutting", "LongestCommonSubsequence", "LongestIncreasingSubsequence",
      "EditDistance", "CoinChange", "KMP", "ZAlgorithm", "RabinKarp", "FiniteAutomata", "TrieNode",
      "BoyerMoore", "SuffixArray", "TreeNode", "BinarySearch", "MinHeap", "MaxHeap", "BinaryIndexedTree",
      "GraphNode", "Edge", "VertexCover", "EulerianPath", "HamiltonianCycle", "GraphColoring",
      "MinimumCut", "MaximumFlow", "FordFulkerson", "EdmondsKarp", "Matchings", "HungarianAlgorithm",
      "HopcroftKarp", "StableMatching", "GaleShapley", "AhoCorasick", "KuhnMunkres", "HeavyLightDecomposition",
      "ArticulationPoint", "BridgeFinding", "Tarjan", "Kosaraju", "SCC", "JohnsonAlgorithm", "FloydCycleDetection",
      "TortoiseHare", "PollardRho", "Exponentiation", "ModuloInverse", "FastFourierTransform", "PolynomialMultiplication",
      "Karatsuba", "ConvexHull", "JarvisMarch", "GrahamScan", "ChanAlgorithm", "DivideAndConquer", "PigeonholePrinciple",
      "CountingSort", "RadixSort", "BucketSort", "StableSort", "CountingInversions", "MergeIntervals", "SweepLineAlgorithm",
      "ManacherAlgorithm", "PalindromicSubstrings", "Fenwick", "BitManipulation", "GrayCode", "BitShift", "BitwiseAnd",
      "BitwiseOr", "BitwiseXor", "Bitmasking", "PowerSet", "Subsequences", "Permutations", "Combinations", "DFSVisit",
      "BFSVisit", "DynamicGraph", "PersistentDataStructure", "LazyPropagation", "BalancedTree", "SelfBalancingTree",
      "RandomizedAlgorithms", "Sum", "Size", "ApproximationAlgorithms"
    ]

    const firstIndex = Math.floor(Math.random() * programmingDSAWords.length);
    let secondIndex = Math.floor(Math.random() * programmingDSAWords.length);

    // Ensure the two indices are different
    while (secondIndex === firstIndex) {
      secondIndex = Math.floor(Math.random() * programmingDSAWords.length);
    }

    let name = programmingDSAWords[firstIndex] + " " + programmingDSAWords[secondIndex]

    // Adding user to room
    const userData = auth.currentUser.uid;
    const email = auth.currentUser.email;
    const roomuserRef = ref(database, "rooms/" + random_id + "/users");
    const newroomusersRef = push(roomuserRef);
    set(newroomusersRef, {
      uuid: userData,
      email: email,
      name: auth.currentUser.displayName,
    });

    const apiquesRef = ref(database, "rooms/" + random_id + "/questions-api");
    const apiroomquesref = push(apiquesRef)
    set(apiroomquesref, ques_details.slice(0, 4))

    const liveroomRef = ref(database, "rooms/" + random_id + "/live/")
    set(liveroomRef, { 'is_live': false })

    const roomNameRef = ref(database, "rooms/" + random_id + "/name");
    set(roomNameRef, { 'name': name })

    const roomLevelRef = ref(database, "rooms/" + random_id + "/level");
    set(roomLevelRef, { 'level': selectedOption })
  }


  const onSubmit2 = async (e) => {
    setLoading(true)
    await performAsyncLoop();

    await create();

    navigate("/rooms/" + random_id, {
      state: { from: random_id, ques_details: ques_details },
    });
  };

  return (
    <>
    <Navbar />
    {loading ? <CreateRoomSpinner /> : 
      <center>
        <div className="card w-2/5 max-md:w-[60%] max-sm:w-[85%] bg-white shadow-xl text-black mt-12 ">
          <div className="card-body">
            <h1 className="text-2xl font-semibold">Create Room</h1>

            <input type="hidden" name="remember" defaultValue="true" />
            <div className="rounded-md shadow-sm mb-16">
              <div className="text-left text-lg">
                <div>Note :</div>
                <div><FontAwesomeIcon icon={faCircleArrowRight} style={{ color: '#3f3cbb' }} /> You'll get 4 questions in the Room.</div>
                <div>
                  <FontAwesomeIcon icon={faCircleArrowRight} style={{ color: '#3f3cbb' }} /> You can invite your friends in the room by simply sharing
                  them the room link which you will get inside.
                </div>
              </div>
              <div></div>
            </div>

            <div>
              <>
                <div className="text-lg">
                  <h1>Select Problems Difficulty :</h1>
                  <select value={selectedOption} onChange={handleSelectChange} className="bg-white text-black p-2 border-dotted rounded" style={{ border: '1px dotted black' }}>
                    <option value="low">Easy</option>
                    <option value="medium">Medium</option>
                    <option value="hard">Hard</option>
                  </select>

                  <p>Selected Difficulty: {selectedOption}</p>
                </div>
                <br></br>
                <button
                  type="submit"
                  className="group bg-purple relative flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-lg font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  onClick={onSubmit2}
                >
                  {/* <span className="absolute inset-y-0 left-0 flex items-center pl-3"></span> */}
                  Create
                </button> </>

            </div>
          </div>
        </div>
      </center>}
    </>
  );
}

export default RoomCreate;

import React from "react";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import Speech from "react-speech";
import {
  Link,
  UNSAFE_enhanceManualRouteObjects,
  useNavigate,
} from "react-router-dom";

import { useState, useEffect } from "react";
import axios from "axios";
import { getDatabase, ref, set, push, remove } from "firebase/database";
import { getAuth } from "firebase/auth";
import { useAuthStatus } from "../hooks/useAuthStatus";
// import { useSpeechSynthesis } from "react-speech-kit";
import Navbar from "../components/Navbar/Navbar";
import { onValue } from "firebase/database";


function OsInterview() {
  const [start_interview, setstart_interview] = useState(false);
  const [question, setquestion] = useState("");
  const auth = getAuth();
  const database = getDatabase();
  const navigate = useNavigate();
  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
  } = useSpeechRecognition();
  const [showModal, setShowModal] = useState(false);

  // const { speak } = useSpeechSynthesis();
  const textstyle = {
    play: {
      hover: {
        backgroundColor: "black",
        color: "white",
      },
      button: {
        padding: "4",
        fontFamily: "Helvetica",
        fontSize: "1.0em",
        cursor: "pointer",
        pointerEvents: "none",
        outline: "none",
        backgroundColor: "inherit",
        border: "none",
      },
    },
  };

  const { Configuration, OpenAIApi } = require("openai");
  const interviewRef = ref(database, "interview/os" + auth.currentUser.uid);
  const [interview_questions, setinterview_questions] = useState([]);
  const [seconds, setSeconds] = useState(60);
  const [time_up, settime_up] = useState(false);

  const os_questions = [
   " Why is the operating system important?",
   "What's the main purpose of an OS? What are the different types of OS?",
   " What are the benefits of a multiprocessor system?",
   " What is RAID structure in OS? What are the different levels of RAID configuration?",
   "What is GUI?",
   "What is a Pipe and when it is used?",
   "What are the different kinds of operations that are possible on semaphore?",
   'What is a bootstrap program in OS?', 
   ' Explain demand paging?',
    'What do you mean by RTOS?', 
    'What do you mean by process synchronization?', 
    'What is IPC? What are the different IPC mechanisms?', 
    'What is different between main memory and secondary memory.', 
    'What do you mean by overlays in OS?', 
    ' Write top 10 examples of OS?', 
    ' What is virtual memory?', 
    ' What is thread in OS?', 
    'What is a process? What are the different states of a process?', 
    ' What do you mean by FCFS?', 
    'What is Reentrancy?', 
    ' What is a Scheduling Algorithm? Name different types of scheduling algorithms.', 
    ' What is the difference between paging and segmentation?', 
    'What is thrashing in OS?', 
    ' What is the main objective of multiprogramming?', 
    'What do you mean by asymmetric clustering?', 
    'What is the difference between multitasking and multiprocessing OS?', 
    'What do you mean by Sockets in OS?', 
    'Explain zombie process?', 
    'What do you mean by cascading termination?', 
    ' What is starvation and aging in OS?', 
    'What do you mean by Semaphore in OS? Why is it used?', 
    'What is Kernel and write its main functions?', 
    ' What are different types of Kernel?', 
    'Write difference between micro kernel and monolithic kernel?', 
    'What is SMP (Symmetric Multiprocessing)?', 
    ' What is a time-sharing system?', 
    'What is Context Switching?', 
    'What is difference between Kernel and OS?', 
    ' What is difference between process and thread?', 
    'What are various sections of the process?', 
    ' What is a deadlock in OS? What are the necessary conditions for a deadlock?', 
    'What do you mean by Belady’s Anomaly?',
     'What is spooling in OS?']

  const configuration = new Configuration({
    apiKey: "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",
  });
  const openai = new OpenAIApi(configuration);
  const [locationKeys, setLocationKeys] = useState([]);

  const handleevent = () => {
    setShowModal(true);
  };

  useEffect(() => {
    const quesRef = ref(database, "os-interviews/" + auth.currentUser.uid);
    remove(quesRef)
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      } else {
        settime_up(true);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [seconds]);

  const selectrandomquestion = () => {
    const len = os_questions.length;
    let ques = os_questions[Math.floor(Math.random() * len)];
    setquestion(ques);
    // console.log(ques);
    // console.log(question);
  };

  const StartInterview = () => {
    setstart_interview(true);
    selectrandomquestion();
    setSeconds(60);
    settime_up(false);
  };

  const submitAnswer = async () => {
    // console.log(question);
    settime_up(false);
    setSeconds(60);
    const save_user_response_ref = ref(
      database,
      "os-interviews/" + auth.currentUser.uid
    );
    const apiquesRef = ref(
      database,
      "os-interviews/" + auth.currentUser.uid
    );
    const statusRef = push(save_user_response_ref);
    const best_possible_answer = await getbestpossibleanswer(question);

    const rephrased_answer = await rephraseanswer(transcript)
      .then((rephrased_answer) => {
        set(statusRef, {
          question: question,
          answer: transcript,
          best_possible_answer: best_possible_answer.msg,
          rephrased_answer: rephrased_answer.msg,
        });
      })
      .then(
        onValue(apiquesRef, (snapshot) => {
          const data = snapshot.val();
          let questns_count = 0;
          let questns = [];
          for (let d in data) {
            questns_count += 1;
            questns.push(data[d].question);
          }
          setinterview_questions(questns);
          if (questns_count === 3) {
            console.log("Now its 15");
            var random_id = Math.floor(100000000 + Math.random() * 900000000);
            const interviews_completed = ref(
              database,
              "completed-interviews/" + auth.currentUser.uid + "/" + random_id
            );
            onValue(apiquesRef, (snapshot) => {
              const data = snapshot.val();
              let questns_count = 0;
              for (let d in data) {
                const statusRef = push(interviews_completed);
                // console.log(data[d]["question"])
                set(statusRef, {
                  question: data[d]["question"],
                  answer: data[d]["answer"],
                  best_possible_answer: data[d]["best_possible_answer"],
                });
              }
            });

            navigate("/result/" + random_id, {
              state: { from: random_id },
            });
          } else {
            console.log("Not 15 yet");
          }
        })
      );

    prompt =
      "Create a new question for a os developer interview" 
    for (let i = 0; i < 15; i++) {
      if(i%2==0){
        generatequestion(prompt);
      }else{
        selectrandomquestion()
      }
    }
    
  };

  const generatequestion = async (prompt) => {
    const apiCallForBatchSubmissions = {
      method: "POST",
      url: "https://ai-interview-backend-main-pyyqh45b3-rhythmbat205.vercel.app/generate-question",
      // url: "http://127.0.0.1:8000/generate-question",
      headers: {
        "content-type": "application/json",
        "Content-Type": "application/json",
      },
      data: {
        answer: prompt,
      },
    };

    try {
      const postSubmissionResponse = await axios.request(
        apiCallForBatchSubmissions
      );
      const postSubmissionResponseData = postSubmissionResponse.data;
      // console.log("Follow up question : ", postSubmissionResponseData.msg);
      setquestion(postSubmissionResponseData.msg);
    } catch (error) {
      selectrandomquestion();
    }
  };

  const getbestpossibleanswer = async (question) => {
    const apiCallForBatchSubmissions = {
      method: "POST",
      url: "https://ai-interview-backend-main-pyyqh45b3-rhythmbat205.vercel.app/get-best-possible-answer",
      //url: "http://127.0.0.1:8000/get-best-possible-answer",
      headers: {
        "content-type": "application/json",
        "Content-Type": "application/json",
      },
      data: {
        question: question,
      },
    };

    try {
      const postSubmissionResponse = await axios.request(
        apiCallForBatchSubmissions
      );
      const postSubmissionResponseData = postSubmissionResponse.data;
      // console.log("Best possible Answer : ", postSubmissionResponseData.msg);
      return postSubmissionResponseData;
    } catch (error) {
      console.error(error);
    }
  };

  const rephraseanswer = async (answer) => {
    const apiCallForBatchSubmissions = {
      method: "POST",
      url: "https://ai-interview-backend-main-pyyqh45b3-rhythmbat205.vercel.app/rephrase-answer",

      headers: {
        "content-type": "application/json",
        "Content-Type": "application/json",
      },
      data: {
        answer: answer,
      },
    };

    try {
      const postSubmissionResponse = await axios.request(
        apiCallForBatchSubmissions
      );
      const postSubmissionResponseData = postSubmissionResponse.data;
      return postSubmissionResponseData;
    } catch (error) {
      console.error(error.response.data);
    }
  };

  if (!browserSupportsSpeechRecognition) {
    return <span>Browser doesn't support speech recognition.</span>;
  }

  return (
    <div>
      <Navbar />

      <div className="mt-10 bg-base-200">
        <div className="text-center">
          <div className="w-full">
            {!start_interview && (
              <div class="w-full p-8">
                <div class="md:max-w-md mx-auto overflow-hidden rounded-3xl shadow-8xl">
                  <div class="p-9">
                    <span class="mb-7 inline-block text-sm text-gray-500 font-semibold uppercase tracking-px">
                      Instructions :
                    </span>
                    <ul>
                      <li class="mb-4 flex items-center">
                        <svg
                          class="mr-2"
                          width="40"
                          height="20"
                          viewbox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M7.5 10L9.16667 11.6667L12.5 8.33333M17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10Z"
                            stroke="#4F46E5"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                        </svg>
                        <p class="font-semibold leading-normal">
                          You will get 15 os questions to answer.
                        </p>
                      </li>
                      <li class="mb-4 flex items-center">
                        <svg
                          class="mr-2"
                          width="40"
                          height="20"
                          viewbox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M7.5 10L9.16667 11.6667L12.5 8.33333M17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10Z"
                            stroke="#4F46E5"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                        </svg>
                        <p class="font-semibold leading-normal"></p>The button
                        for submitting answer will appear after 60 seconds of
                        opening a question.
                      </li>
                      <li class="mb-4 flex items-center">
                        <svg
                          class="mr-2"
                          width="40"
                          height="20"
                          viewbox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M7.5 10L9.16667 11.6667L12.5 8.33333M17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10Z"
                            stroke="#4F46E5"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                        </svg>
                        <p class="font-semibold leading-normal">
                          Click on the button labelled "Start Recording" to
                          record your answer.
                        </p>
                      </li>
                      <li class="mb-4 flex items-center">
                        <svg
                          class="mr-2"
                          width="60"
                          height="20"
                          viewbox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M7.5 10L9.16667 11.6667L12.5 8.33333M17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10Z"
                            stroke="#4F46E5"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                        </svg>
                        <p class="font-semibold leading-normal">
                          The recording will pause automatically after you stop
                          speaking. However, you can click on "Stop Recording"
                          button to stop the recording.
                        </p>
                      </li>
                      <li class="mb-4 flex items-center">
                        <svg
                          class="mr-2"
                          width="40"
                          height="20"
                          viewbox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M7.5 10L9.16667 11.6667L12.5 8.33333M17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10Z"
                            stroke="#4F46E5"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                        </svg>
                        <p class="font-semibold leading-normal">
                          You will be redirected to your Interview Report page
                          as soon as you answer all the questions.
                        </p>
                      </li>
                    </ul>
                  </div>
                  <div class="p-9">
                    <div class="">
                      <button
                        class="py-4 px-5 w-full text-white font-semibold rounded-xl focus:ring focus:ring-indigo-300 bg-indigo-600 hover:bg-indigo-700 transition ease-in-out duration-200"
                        type="button"
                        onClick={StartInterview}
                      >
                        Start Interview
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div>
        {/* Your main content */}
        {start_interview ? (
          <div className="flex justify-center items-center">
            <div className="w-full p-4">
              <div className="alert w-full h-relative" data-theme="halloween">
                <p className="mr-2 w-96">Q: {question}</p>
                {question ? (
                  <>
                    {/* <button
                      className="btn-outline btn-success mr-12"
                      onClick={() => speak({ text: question })}
                    >
                      Listen Question
                    </button> */}
                  </>
                ) : (
                  <>No question</>
                )}
                <p>Microphone status: {listening ? "on" : "off"}</p>
                <button
                  className="btn btn-info ml-2 mr-2"
                  onClick={SpeechRecognition.startListening}
                >
                  Start Recording
                </button>

                <button
                  className="btn btn-warning  mr-2"
                  onClick={SpeechRecognition.stopListening}
                >
                  Stop Recording
                </button>

                {time_up ? (
                  <button
                    className="btn btn-primary  ml-2 mr-2"
                    onClick={submitAnswer}
                  >
                    Submit Answer
                  </button>
                ) : (
                  <h1>Time left: {seconds} seconds</h1>
                )}
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
        <br></br>
        <center><h1 className="text-xl">Questions Anwered</h1></center>
        <br />
        {interview_questions.map((q) => (
          <>
            <div className="alert w-full h-relative" data-theme="halloween">
              <p>Q. {q}</p>
            </div>
            <br></br>
          </>
        ))}
      </div>
    </div>
  );
};

export default OsInterview
export const languageOptions = [
  {
    id: 54,
    name: "C++ (GCC 9.2.0)",
    label: "C++",
    value: "cpp",
    runtime: "1.0",
  },
  {
    id: 62,
    name: "Java (OpenJDK 13.0.1)",
    label: "Java",
    value: "java",
    runtime: "2.0",
  },

  {
    id: 71,
    name: "Python (3.8.1)",
    label: "Python3",
    value: "python",
    runtime: 5.0,
  },
];


// {
//   id: 63,
//   name: "JavaScript (Node.js 12.14.0)",
//   label: "JavaScript (Node.js 12.14.0)",
//   value: "javascript",
//   runtime: "5.0",
// },
// {
//   id: 75,
//   name: "C (Clang 7.0.1)",
//   label: "C (Clang 7.0.1)",
//   value: "c",
//   runtime: "1.0",
// },
// {
//   id: 76,
//   name: "C++ (Clang 7.0.1)",
//   label: "C++ (Clang 7.0.1)",
//   value: "cpp",
//   runtime: "1.0",
// },
// {
//   id: 48,
//   name: "C (GCC 7.4.0)",
//   label: "C (GCC 7.4.0)",
//   value: "c",
//   runtime: "1.0",
// },
// {
//   id: 52,
//   name: "C++ (GCC 7.4.0)",
//   label: "C++ (GCC 7.4.0)",
//   value: "cpp",
//   runtime: "1.0",
// },
// {
//   id: 49,
//   name: "C (GCC 8.3.0)",
//   label: "C (GCC 8.3.0)",
//   value: "c",
//   runtime: "1.0",
// },
// {
//   id: 53,
//   name: "C++ (GCC 8.3.0)",
//   label: "C++ (GCC 8.3.0)",
//   value: "cpp",
//   runtime: "1.0",
// },
// {
//   id: 50,
//   name: "C (GCC 9.2.0)",
//   label: "C (GCC 9.2.0)",
//   value: "c",
//   runtime: "1.0",
// },
// {
//   id: 54,
//   name: "C++ (GCC 9.2.0)",
//   label: "C++ (GCC 9.2.0)",
//   value: "cpp",
//   runtime: "1.0",
// },
// {
//   id: 62,
//   name: "Java (OpenJDK 13.0.1)",
//   label: "Java (OpenJDK 13.0.1)",
//   value: "java",
//   runtime: "2.0",
// },
// {
//   id: 70,
//   name: "Python (2.7.17)",
//   label: "Python (2.7.17)",
//   value: "python",
//   runtime: "5.0",
// },
// {
//   id: 71,
//   name: "Python (3.8.1)",
//   label: "Python (3.8.1)",
//   value: "python",
//   runtime: "5.0",
// },
// {
//   id: 53,
//   name: "C++ (GCC 8.3.0)",
//   label: "C++ (GCC 8.3.0)",
//   value: "cpp",
//   runtime: "1.0",
// },
// {
//   id: 70,
//   name: "Python (2.7.17)",
//   label: "Python (2.7.17)",
//   value: "python",
//   runtime: "5.0",
// },
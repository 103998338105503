  import React from 'react'
  import spinner from '../assets/spinner.gif'

  function RoomSpinner() {
    return (
      <center><div className="w-50 mt-8 ml-66">
      <img
      width={50}
      className='text-center mx-auto'
      src={spinner}
      alt='Loading..'
  />
  <h2 className='text-2xl'>Processing Testcases⌛</h2>
  </div></center>
    )
  }

  export default RoomSpinner
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { getDatabase, ref, set, push, remove } from "firebase/database";
import { getAuth } from "firebase/auth";
import { useAuthStatus } from "../hooks/useAuthStatus";
// import { useSpeechSynthesis } from "react-speech-kit";
import Navbar from "../components/Navbar/Navbar";
import { onValue } from "firebase/database";

function ResultPage() {
  const location = useLocation();
  const { from } = location.state;
  const [interview_questions, setinterview_questions] = useState([]);
  const auth = getAuth();
  const database = getDatabase();

  useEffect(() => {
    const interview_ref = ref(
      database,
      "completed-interviews/" + auth.currentUser.uid + "/" + from
    );
    onValue(interview_ref, (snapshot) => {
      const data = snapshot.val();
      let interview_res = [];
      for (let d in data) {
        interview_res.push({
          question: data[d]["question"],
          answer: data[d]["answer"],
          best_possible_answer: data[d]["best_possible_answer"],
        });
      }
      setinterview_questions(interview_res);
    });
  }, []);

  return (
    <div>
      <center><h3 className="text-2xl">Report</h3></center>
      <br></br>
      <div className="w-full">
        <table className="table table-zebra">
          {/* head */}
          <thead>
            <tr>
              <th>Question</th>
              <th>Your Answer</th>
              <th>Best Answer</th>
            </tr>
          </thead>
          <tbody>
            {/* row 1 */}
            {interview_questions.map((q) => (
              <tr>
                <th style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}>{q.question}</th>
                <td style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}>{q.answer}</td>
                <td style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}>{q.best_possible_answer}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default ResultPage;

import React from 'react'

function Roomques() {
  return (
    <div>
      Roomques
    </div>
  )
}

export default Roomques

import React from 'react'
import { getDatabase, ref, onValue} from "firebase/database";

function Messages() {
    
  return (
    <>
    
              {/* <div className="artboard phone-1" data-theme="light">
              
              </div> */}

<div className="grid w-88 card bg-base-300 rounded-box place-items-center" data-theme="light"><div className="card w-full bg-base-100 shadow-xl" data-theme="light">
  
  <div className="card-body items-center text-center">
 
  </div>
</div></div>
            
    </>
  )
}

export default Messages
import React from 'react'
import { getDatabase, ref, set,push } from "firebase/database";
import {database} from '../firebase.config'
import {getAuth} from 'firebase/auth'
import { useState,useEffect } from 'react';
import {toast} from 'react-toastify'
import { Link, useNavigate } from 'react-router-dom'
import {db} from '../firebase.config'
import {setDoc, doc, serverTimestamp} from 'firebase/firestore'
import { onValue } from 'firebase/database';
import { Checkbox } from "@material-tailwind/react";
import RoomForm from './RoomForm';
import data from '../data/data.json'


function Discussion() {
    const auth = getAuth()
    const database = getDatabase();

    const [modal, setModal] = useState(false)
    const [check1, setCheck1] = useState(false)
    const [check2, setCheck2] = useState(false)
    const [check3, setCheck3] = useState(false)
    const [check4, setCheck4] = useState(false)

    const [all_questions, set_all_questions] = useState([])
    const [room_questions, setroom_questions] = useState([])
    

    const topics = [
        {
            id:1,
            name: 'Array',
            isChecked: false,
        },
        {
            id:2,
            name: 'Trees',
            isChecked: false,
        }
    ]


    const questions = [
        {
          id: 1,
          name: 'Island Perimeter',
          link: 'https://leetcode.com/problems/island-perimeter/',
        },
        {
            id: 2,
            name: 'Diagonal Traverse',
            link: 'https://leetcode.com/problems/diagonal-traverse/',
        },
        {
            id: 3,
            name: 'Find Center of Star Graph',
            link: 'https://leetcode.com/problems/find-center-of-star-graph/',
        },
        {
            id: 4,
            name: 'Network Delay Time',
            link: 'https://leetcode.com/problems/network-delay-time/',
        },
        
      ]
      
    
    const [all_messages, setAll_messages] = useState([])

    const options = {
        onOpen: props => console.log(props.foo),
        onClose: props => console.log(props.foo),
        autoClose: 6000,
        type: toast.TYPE.INFO,
        hideProgressBar: false,
        position: toast.POSITION.TOP_CENTER,
        pauseOnHover: true,
        progress: 1
        
    };
    
    const [solved_user, setSolved_user] = useState([])
    const [all_rooms, setall_rooms] = useState([])
    useEffect(()=> {
            const all_messages = ref(database, 'messages');

            const all_solved = ref(database, 'solved/'+auth.currentUser.uid);

            const all_rooms = ref(database, 'rooms/');

            

                onValue(all_solved, (snapshot) => {
                    const data = snapshot.val();
                    let answers = []
                    for (let d in data ){
                        answers.push(
                                data[d].name,
                            // link:data[d].link
                        )
                    }
                    setSolved_user(answers)
                    
                 }); 
            
                onValue(all_messages, (snapshot) => {
                    const data = snapshot.val();
                    let messages = []
                    for (let d in data ){
                        messages.push({
                            message:data[d].message,
                            user:data[d].user
                        })
                    }
                    setAll_messages(messages)
                    //console.log(messages)
                    let objDiv = document.getElementById("msg")
                    objDiv.scrollTop = objDiv.scrollHeight                    
                }); 
                
                onValue(all_rooms, (snapshot) => {
                    const data = snapshot.val();
                    //console.log(data)
                    let rooms = []
                    // let topic_Arr = []
                    for (let d in data){
                        rooms.push(d)
                    }
                    setall_rooms(rooms.slice(0,8))
                       
                });
            
                toast.success("Now you can create rooms and solve and discuss questions with your friends!", options); 
            
            
            
    }, [])



    // const [solved_user, setSolved_user] = useState([])
//     useEffect(()=> {
//     const all_solved = ref(database, 'solved/'+auth.currentUser.uid);

//         onValue(all_solved, (snapshot) => {
//             const data = snapshot.val();
//             let answers = []
//             for (let d in data ){
//                 answers.push(
//                         data[d].name,
//                     // link:data[d].link
//                 )
//             }
//             setSolved_user(answers)
            
//     }); 
    
// }, [])



    const onclick1 = async (id,name,link) => {
            try{
            let newDate = new Date()
            let date = newDate.getDate();
            
            const postListRef = ref(database, 'solved/'+auth.currentUser.uid);
            const newPostRef = push(postListRef);
            //console.log(newPostRef.key)
            if(solved_user.includes(name) === false){
            set(newPostRef, 
                {
                    id:id,
                    name:name,
                    link:link,
                }
            );
            //setCheck1(true)
            toast.success("Great Job done! Congratulations of solving the question.The question has been saved to your profiles for future access!", options); 
            }
         }
            catch {

            }
    }


    const [formData, setFormData] = useState({
        message: '',
    })
    const { message }= formData
    const navigate = useNavigate()

    const onChange  = (e) => {
        setFormData((prevState) => ({
            ...prevState,
            [e.target.id] : e.target.value
        }))

    }


    


    const onSubmit = async (e) => {
        e.preventDefault()
        if(formData.message.length > 0){
            try{

            
            const formDataCopy = {...formData}
            formDataCopy.user = auth.currentUser.displayName
            
            const postListRef = ref(database, 'messages');
            const newPostRef = push(postListRef);
            set(newPostRef, formDataCopy);

            // const all_messages = ref(database, 'messages');
            //     onValue(all_messages, (snapshot) => {
            //         const data = snapshot.val();
            //         let messages = []
            //         for (let d in data ){
            //             messages.push({
            //                 message:data[d].message,
            //                 user:data[d].user
            //             })
            //         }
            //         setAll_messages(messages)
            //         console.log(messages)
            // });
            let objDiv = document.getElementById("msg")
            objDiv.scrollTop = objDiv.scrollHeight
            setFormData(
                {message:''}
            )
  
            navigate('/discuss')
            }
        
        catch(error) {
            //console.log(error)
            toast.info('Please type a message')
        }
    }else{
        toast.info('Please type a message')
    }
}   
        const [roomCreate , setRoomCreate] = useState(false)
        const [roomTopics, setRoomTopics] = useState([])
        const createRoom = async (e) => {
            setRoomCreate(true)
        }

        let temp_topics = []
        const setchecked = async (id) => {
            const t = topics.filter(topics => topics.id === id)
            t.map((tot) => (
                tot.isChecked === false ? tot.isChecked = true : tot.isChecked = false
            ))

            t.map((tot) => (
                tot.isChecked === true ? temp_topics.push(tot.name) : tot.isChecked = false
            ))
        }

        const onSubmit2 = async (e) => {
            //e.preventDefault()
            setRoomTopics(temp_topics)
            temp_topics.map((tmp) => (
                roomTopics.push(tmp)
            ))
            console.log(roomTopics)
            const random_id = Math.floor(100000000 + Math.random() * 900000000);
            const roomRef = ref(database, 'rooms/'+random_id + '/topics'); 
          
            const newroomRef = push(roomRef);
            set(newroomRef, roomTopics);



            
            const userData =  auth.currentUser.uid
            

            const roomuserRef = ref(database, 'rooms/'+random_id + '/users');
            const newroomusersRef = push(roomuserRef);
            set(newroomusersRef, 
                {
                    uuid:userData,
                });
            {data.map((d) => (
                all_questions.push(
                    {
                        title:d.stat.question__title,
                        url:"https://leetcode.com/problems/" + d.stat.question__title_slug
                    }
                )
                //{d.stat.question__title_slug}
            ))}
        
            const roomquesRef = ref(database, 'rooms/'+ random_id + '/questions');
            const dbRef = ref(getDatabase());
            for (let i = 0; i < 4; i++) {
                const len = all_questions.length;
                room_questions.push(all_questions[Math.floor(Math.random() * len)])
            }
            const newroomquesRef = push(roomquesRef);
            set(newroomquesRef, room_questions);
             

            

            navigate('/rooms/'+random_id,{state:{room_key:newroomRef.key,topics:roomTopics,room_id:random_id,from:random_id}})

        }









   

  return (
   
        <>
         <>
        
         
           {/* <a href="#my-modal-2" className="btn">Create Room</a> */}
            {/* <form className="h-96" onSubmit={onSubmit2}>
            <div className="modal" id="my-modal-2">
            <div className="modal-box">
                <h3 className="font-bold text-lg">Select Topics</h3>
                <p className="py-4"></p>
                { topics.map((t) => (
                <div className="form-control">
                <label className="cursor-pointer label">
                    <span className="label-text mr-5">{t.name}</span>
                    <p>{t.isChecked}</p>
                    <input type="checkbox" className="checkbox checkbox-info ml-5" onClick={() => setchecked(t.id)}/>
                </label>
            </div>
                ))}

                
                
            <div className="modal-action">
            <button
             type="submit"
            className="btn btn-primary"
            >
            Create Room
            </button>
            </div>
            </div>
            </div>
            </form> 
            */}
            </> 
         


            <div className="flex lg:flex-row flex-col">
      <div className="grid flex-grow w-16 mr-0 h-full">
      
      
          <div className="card-body">
            
          
            <Link to='/roomcreate'><button className='btn btn-outline btn-info  btn-sm'>Create Your Room</button></Link>
            <Link to='/enter-room'> <button className='btn  btn-outline btn-info btn-sm'>Enter A Room</button></Link>
            <center><h2 className="card-title">Top Rooms</h2></center>
            <center>{roomCreate && <RoomForm />} </center>
            
           {all_rooms.map((r) => (
           <Link to={`/rooms/${r}`} state={{ from: r }}>
            <div className="card w-48 bg-base-100 shadow-xl h-20">
            <center><p class="font-semibold leading-normal mt-8">Room id - {r}</p></center>
            </div>
            </Link> 
            
          ))}  
            
        </div>
      </div>
    
      
      <div className="divider lg:divider-horizontal"></div> 
      <div className="grid flex-grow mr-5">       

<section class="relative  overflow-hidden">
      
    <div class="relative z-10 container px-4 mx-auto">
      <div class="flex flex-wrap ">
        
        <div class="w-full md:w-1/2 p-8">
          <div class="md:max-w-md mx-auto overflow-hidden rounded-3xl shadow-8xl">
          
            <div class="p-9">
                
              <ul>
              { questions.map((q) => (
                <li class="mb-4 flex items-center">
                <div className="grid h-32 flex-grow card bg-base-300 rounded-box place-items-center">
                <h3 className='text-xl'>{q.name}</h3>
                    
                    <div className="flex flex-row mr-15 ml-15">
                        <a href={q.link} target='false'>
                            <button className="btn btn-sm">Solve</button>
                        </a>
                        <div className="divider divider-horizontal"></div>
                        { solved_user.includes(q.name) ? 
                        <button className="btn btn-sm btn-success">Great Job</button>
                        :
                         <button className="btn btn-sm btn-success" onClick={() => onclick1(q.id,q.name,q.link) }>Solved?</button> 
                        
                       
                         
                        }
                    </div>
                </div>
                </li>
                    ))}

              </ul>
            </div>
          </div>
          <br></br>
          <br>
          </br>
          <center>
    <h1 className='text-4xl'>Pre-requisites to solve the questions:</h1>
    <br></br>
    <ul>
        <li className='text-2xl'>1. Matrix</li>
        <li className='text-2xl'>2. Graphs</li>
         {/* <li className='text-2xl'>3. Binary Trees</li>  */}
         {/*<li className='text-2xl'>4. Binary Trees</li> */}
        
    </ul>
    </center>
        </div>

        <div class="w-full md:w-1/2 p-8">
  
          <div class="md:max-w-md">
          <div class="overflow-auto" id="msg">
            <div className="w-full">    
              <div className="artboard phone-1">
                <center><h1 className='text-4xl'>Discussion Forum</h1></center>
                <center><h1>Discuss your solutions/doubts here!</h1></center>
                <p>Note: The Chats get deleted everyday.</p>
                  { all_messages.map((m) => (
                      
                      <>
                      <div className="">
                      <div>
                            <br></br>
                          <b>{m.user}</b> : 
                          <br></br>
                          <p class="font-semibold leading-normal">{m.message}</p>
                          
                      </div>
                      </div>
                      </>
                      
                      
                  ))
                  
                  
                  } 
  
                
              </div>
              
              </div>
             
              </div>
            
          </div>
            <br></br>
          <form className="h-96" onSubmit={onSubmit}>
            <div className='flex flex-row'>
                <div className='w-full'>
                <input 
                    type="text" 
                    id='message' 
                    value={message} 
                    placeholder="Type here" 
                    className="input input-bordered input-primary w-3/4"
                    onChange={onChange}
                    />
                </div>
                 <br></br>
                 
            <button
             type="submit"
            className="btn btn-primary"
            >
            Send
            </button>
       
        </div>
        </form>
  
        </div>
        
      </div>
    
    </div>
    
  </section> 
  </div>
  </div>
  
























    
            {/* <center><h1 className='text-4xl'>Problems/Discuss</h1></center>
            <div className="flex w-full ">
                <div className="grid h-40 flex-grow card bg-base-300 rounded-box place-items-center">
                    Problem 1
                    <button className="btn btn-sm">Solve</button>
                </div>
                <div className="divider divider-horizontal lg:divider-horizontal"></div>
                <div className="grid h-40 flex-grow card bg-base-300 rounded-box place-items-center">
                    Problem 2
                    <button className="btn btn-sm">Solve</button>
                </div>
                <div className="divider divider-horizontal lg:divider-horizontal"></div>
                <div className="grid h-40 flex-grow card bg-base-300 rounded-box place-items-center">
                    Problem 3
                    <button className="btn btn-sm">Solve</button>
                </div>
                <div className="divider divider-horizontal lg:divider-horizontal"></div>
                <div className="grid h-40 flex-grow card bg-base-300 rounded-box place-items-center">
                    Problem 4
                    <button className="btn btn-sm">Solve</button>
                </div>
            </div>
            
                <br></br>
            <br></br>
          
            <div class="overflow-auto" id="msg">
            <div className="artboard artboard-horizontal phone-5 ml-20" >
            
                {  all_messages.map((m) => (
                    
                    <>
                    <div className="alert shadow-lg w-100">
                    <div>
                        <span><b>{m.user}</b> : {m.message}</span>
                    </div>
                    </div>
                    </>
                    
                ))} 

        
            </div>
            </div>
        <div class="fixed bottom-30 h-16 w-full  px-4 py-6 z-10 text-blue mb-5">
        <div class='w-full h-96'>          
        <form className="h-96" onSubmit={onSubmit}>
            <div className='flex flex-row'>
                <div className='w-full mr-5 ml-10'>
                <input 
                    type="text" 
                    id='message' 
                    value={message} 
                    placeholder="Type here" 
                    className="input input-bordered input-primary w-full max-w-6x ml-600"
                    onChange={onChange}
                    />
                </div>
                 <br></br>
                 <div>
            <button
             type="submit"
            className="btn btn-primary mr-10"
            >
            Send
            </button>
        </div>
        </div>
        </form>
        </div>  
        </div> */}
        
        </>
 
    
  )
}

export default Discussion
import React, { useState } from "react";

import Editor from "@monaco-editor/react";

function RoomCodeEditor({ onChange, language, code, theme }) {
    const [value, setValue] = useState(code || "");

    const handleEditorChange = (value) => {
      setValue(value);
      onChange("code", value);
    };
  
    return (
      <div className="w-full h-112">
        <Editor
          height="68vh"
          language={language || "javascript"}
          value={value}
          theme="pastels-on-dark"
          defaultValue="// start here"
          onChange={handleEditorChange}
        />
      </div>
    );
  };

export default RoomCodeEditor
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {getFirestore} from 'firebase/firestore'
import { getDatabase } from "firebase/database";
import { getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword, signInWithPopup, GoogleAuthProvider, signOut } from 'firebase/auth';

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyC4CbY8RvSdxl4HkXNoaLdHTOX5d_pim9w",
  authDomain: "ed-tech-7061d.firebaseapp.com",
  databaseURL: "https://ed-tech-7061d-default-rtdb.firebaseio.com",
  projectId: "ed-tech-7061d",
  storageBucket: "ed-tech-7061d.appspot.com",
  messagingSenderId: "686451700417",
  appId: "1:686451700417:web:57f7252e2daca2b5451a40",
  measurementId: "G-KJ9BRJ430E"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const db = getFirestore()
export const database = getDatabase();
const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();

export const signInWithGoogle = async () => {
  try {
      const result = await signInWithPopup(auth, googleProvider);
      return result.user;
  } catch (error) {
      console.error("Error signing in with Google", error);
      throw error;
  }
};

export const signUpWithEmailAndPassword = async (email, password) => {
  try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      return userCredential.user;
  } catch (error) {
      console.error("Error signing up with email and password", error);
      throw error;
  }
};

export const signInWithEmail = async (email, password) => {
  try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      return userCredential.user;
  } catch (error) {
      console.error("Error signing in with email and password", error);
      throw error;
  }
};

export const logOut = async () => {
  try {
      await signOut(auth);
  } catch (error) {
      console.error("Error signing out", error);
      throw error;
  }
};

export { auth };
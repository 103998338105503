import React, { useEffect, useState } from 'react';

import { getDatabase, ref, set, push, onValue } from "firebase/database";
import { getAuth } from "firebase/auth";
import axios from 'axios';

import Landing from './Landing';
import Spinner from '../components/Spinner';

const ProblemOfTheDay = () => {
    const auth = getAuth();
    const database = getDatabase();
    const problemOfTheDayRef = ref(database, "problemOfDay/")
    const [questionData, setQuestionData] = useState({})
    const [questionId, setQuestionId] = useState(0)
    const [currentDate, setCurrentDate] = useState(0)

    useEffect(() => {
        const fetchData = async () => {
            var fireBaseResponse = await myAsyncFirebaseFunction();

            var APIResponse = await myAsyncAPIFunction(fireBaseResponse.date, fireBaseResponse.question_id);
            setQuestionId(APIResponse)
        };

        fetchData();




    }, []);

    const myAsyncFirebaseFunction = async () => {
        return new Promise((resolve, reject) => {
        onValue(
            problemOfTheDayRef, (snapshot) => {
                if (snapshot.exists()) {
                    resolve(snapshot.val());
                } else {
                    reject(new Error('Data not found')); // Reject the promise if data does not exist
                }
            })
        })
    }


    const myAsyncAPIFunction = async (currentDate, questionId) => {
        const todayDate = new Date();
        let day = todayDate.getDate();
        
        if (currentDate !== day) {
            var response = ''
            try {
                response = await axios.get("http://127.0.0.1:8000/problemsInfo/", { mode: "no-cors" })
                console.log(response)
                console.log("Inside Try")
            } catch (error) {
                console.error('API request failed:', error.message)
            }
            const minNumber = 2
            const maximumNum = response.data.length + 1
            const randomQuestionId = Math.floor(Math.random() * (maximumNum - minNumber) + minNumber);

            set(problemOfTheDayRef, {
                date: day,
                question_id: randomQuestionId
            })
            return randomQuestionId
        }
        return questionId
    }



    return (
        <>
            {questionId>1 ? 
            <Landing ques_id={questionId} /> : <Spinner/>
            }

        </>

    );
}

export default ProblemOfTheDay;
import { useState, useEffect } from "react";
import {
  getDatabase,
  ref,
  set,
  push,
  get,
  child,
  remove,
  update,
} from "firebase/database";
import { onValue } from "firebase/database";
import { getAuth } from "firebase/auth";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


function Timer({ from, start }) {
  const [timeRemaining, setTimeRemaining] = useState(0);
  const [timer_started, settimer_started] = useState(false);
  const database = getDatabase();
  const auth = getAuth();
  const timerRef = ref(database, "rooms/" + from + "/timer");
  const [time, setTime] = useState(0);
  const [minutes, setminutes] = useState(0);
  const [seconds, setseconds] = useState(0);
  const [all_users_ready, setall_users_ready] = useState(false)
  const [length_of_all_users_list, setlength_of_all_users_list] = useState(0)
  const [already_started, setalready_started] = useState(false)

  useEffect(() => {
    const intervalId = setInterval(() => {
      onValue(
        timerRef,
        (snapshot) => {
          const roomData = snapshot.val();
          if (roomData.timer_completed === false) {
            const { startTime } = roomData;
            const elapsedTime = Date.now() - startTime;
            setTime(elapsedTime);
            var minutes_var = Math.floor(elapsedTime / 60000);
            var seconds_var = ((elapsedTime % 60000) / 1000).toFixed(0);
            setminutes(minutes_var);
            setseconds(seconds_var);
            settimer_started(true);
            if (minutes_var >= 60) {
              set(timerRef, { startTime: Date.now(), timer_completed: true });
              settimer_started(false);
              toast.success(`Time Up`, {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
          }
        },
        {
          onlyOnce: true,
        }
      );

      
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const startTimer = () => {
      if(timer_started ===false ){
      set(timerRef, {
      startTime: Date.now(),
      timer_completed: false,
      timer_started : true
    });
  }  
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  };

  return (
    <center>
      <div>
        {timer_started ? (
          <div className="">
            <button className="btn btn-outline btn-primary">Time Elapsed: {minutes + ":" + (seconds < 10 ? "0" : "") + seconds}</button>
          </div>
        ) : (
          // <button className="btn btn-secondary" onClick={startTimer}>
          //   Start timer
          // </button>
          start ? (startTimer()) : (null)
        )}
      </div>
    </center>
  );
}

export default Timer;

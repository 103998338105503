import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Navbar from "../components/Navbar/Navbar";
import { LockClosedIcon } from "@heroicons/react/20/solid";
import { signInWithEmail, signInWithGoogle, auth } from '../firebase.config'
import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function SignIn() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [failedLogIn, setFailedLogIn] = useState(false)

  const navigate = useNavigate();


  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        navigate('/allrooms');
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  const handleSignIn = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmail(email, password);
    } catch (error) {
      setFailedLogIn(true);
    }
  }

  const handleGoogleSignIn = async () => {
    try {
      await signInWithGoogle();
    } catch (error) {
      setFailedLogIn(true);
    }
  }

  return (
    <>
      <Navbar />
      <div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="w-full max-w-md space-y-8">
          <div>
            <br></br>
            <h2 className="mt-6 text-center text-3xl font-bold tracking-tight">
              Sign In
            </h2>
          </div>
          {failedLogIn && <div className="flex items-center bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4" role="alert">
            <strong className="font-bold ml-2xx1">❌</strong>
            <span className="block sm:inline ml-4">Wrong email ID or password. Please try again.</span>
          </div>}
          <form
            className="mt-8 space-y-6"
            action="#"
            method="POST"
            onSubmit={handleSignIn}
          >
            <input type="hidden" name="remember" defaultValue="true" />
            <div className="-space-y-px rounded-md shadow-sm">
              <div>
                <label htmlFor="email-address" className="sr-only">
                  Email address
                </label>
                <input
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  className="relative block w-full appearance-none rounded border border-gray-300 px-3 py-2  placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 text-lg"
                  placeholder="Email address"
                />
              </div>
              <div>
                <label htmlFor="password" className="sr-only">
                  Password
                </label>
                <input
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  className="relative block w-full appearance-none rounded border border-gray-300 px-3 py-2 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 text-lg"
                  placeholder="Password"
                />
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="group relative flex w-full justify-center rounded-md border bg-indigo-600 py-2 px-4 text-lg font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                  <LockClosedIcon
                    className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400"
                    aria-hidden="true"
                  />
                </span>
                Sign in
              </button>
            </div>
          </form>

          <hr></hr>
          <div className="rounded-full w-fit h-fit m-auto mt-8 flex border p-2 cursor-pointer" onClick={handleGoogleSignIn}>
            <FontAwesomeIcon icon={faGoogle} />
          </div>
        </div>
      </div>

      <center>
        <Link to="/sign-up" className="registerLink">
          <button class="btn btn-active btn-primary">Sign Up Instead</button>
        </Link>
      </center>

      {/* <center className="my-8">
        <Link to="/">
          <button class="btn btn-active btn-primary">Home</button>
        </Link>
      </center> */}

      <br></br>
    </>
  );
}

export default SignIn;

import React from "react";
import { Link } from "react-router-dom";
import { useAuthStatus } from "../../hooks/useAuthStatus";
import logo from '../../assets/logo-bg.png'
import './Navbar.css'

function Navbar(props) {
  const { loggedIn, checkingStatus } = useAuthStatus();

  return (
    <>
      <nav className="navbar">
        <div class="menu-wrap">
          <input type="checkbox" class="toggler" />
          <div class="hamburger">
            <div></div>
          </div>
          <div class="menu">
            <div>
              <div>
                <ul>
                  <li><a href="/problems">Problems</a></li>
                  <li><a href="/allrooms">Rooms</a></li>
                  <li><a href="/jobs">Jobs</a></li>
                  {loggedIn ? (<li><a href="/profile">Profile</a></li>) : (<li><a href="/sign-in">Sign in</a></li>)}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <Link to={'/'}>
          <div className="logo cursor-pointer">
            <img
              className='text-center mx-auto h-[100px]'
              src={logo}
            />
          </div>
        </Link>

        <div className="nav-button">
          <ul>
            <Link to="/problems"><li><a href="">Problems</a></li></Link>
            <Link to="/allrooms"><li><a href="">Rooms</a></li></Link>
            <Link to="/jobs"><li><a href="">Jobs</a></li></Link>
            <Link to="/profile"><li><a href="#how-it-works">Profile</a></li></Link>
          </ul>
        </div>

        {loggedIn ? (
          <div className="call-to-action-nav">
            <Link to="/profile">
              <button className="btn-primary" style={{ color: "black", backgroundColor: 'white' }}>Profile</button>
            </Link>
          </div>
        ) :
          (
            <div className="call-to-action-nav">
              <Link to="/sign-in">
                <button className="btn-primary" style={{ color: "black", backgroundColor: 'white' }}>Sign In</button>
              </Link>
            </div>
          )}
      </nav>
    </>
  );
}

export default Navbar;

import React from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  getAuth,
  createUserWithEmailAndPassword,
  updateProfile,
} from "firebase/auth";
import { db } from "../firebase.config";
import { setDoc, doc, serverTimestamp } from "firebase/firestore";
import { toast } from "react-toastify";
import { LockClosedIcon } from "@heroicons/react/20/solid";
import first from "../assets/1stprize.jpg";
import second from "../assets/2nd.jpg";
import third from "../assets/3rd.jpg";
import { getDatabase, ref, set, push } from "firebase/database";
import { useAuthStatus } from "../hooks/useAuthStatus";
import Navbar from "../components/Navbar/Navbar";

function Contestform() {
  const auth = getAuth();
  const database = getDatabase();
  const [registered, setregistered] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone_number: "",
    university_or_company: "",
  });
  const { name, email, phone_number, university_or_company } = formData;

  const { loggedIn, checkingStatus } = useAuthStatus();

  const navigate = useNavigate();

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  };

  const onSubmit = async (e) => {
    const registraton_ref = ref(database, "contest-registration/");
    e.preventDefault();

    try {
      const statusRef = push(registraton_ref);
      set(statusRef, {
        name: name,
        email: email,
        phone_number: phone_number,
        university_or_company: university_or_company,
      });
      setregistered(true);
      setFormData({
        name: "",
        email: "",
        phone_number: "",
        university_or_company: "",
      });
      // navigate("/");
    } catch (error) {
      toast.error("Something went wrong with Registeration");
    }
  };

  return (
    <>
    <Navbar />
    <div className="hero min-h-screen bg-base-200">
      <div className="hero-content text-center">
        <div className="max-w-md">
          <h1 className="text-5xl font-bold">Hola Coders!</h1>
          <p className="py-6">
            We are excited to announce our upcoming Coding Contest! It's an
            opportunity for you to showcase your programming skills and compete
            against fellow coders. Here are the details:
          </p>
          <p className="py-6">
            There will be total 4 Data Structures and Algorithm problems.
          </p>
          <ul>
            <li>
              <span class="highlight">Date:</span> 25/06/23
            </li>
            <li>
              <span class="highlight">Time:</span> 9:00 pm IST
            </li>
            <li>
              <span class="highlight">Duration:</span> 1 hour
            </li>
            <li>
              <span class="highlight">Prizes:</span> The top 3 winners will
              receive exciting prizes!
            </li>
          </ul>

          <div className="flex flex-col w-full lg:flex-row">
            <div className="grid flex-grow h-54 card bg-base-300 rounded-box place-items-center">
              <b>First Price:</b> boAt Immortal 121 TWS Wireless Gaming
              Earbuds 
              <img
                style={{ width: 100, height: 100 }}
                src={first}
                alt="Burger"
              ></img>
            </div>
            <div className="divider lg:divider-horizontal"></div>
            <div className="grid flex-grow h-54 card bg-base-300 rounded-box place-items-center">
              <b>Second Price:</b> Cosmic Byte GS430 Gaming wired over ear
              Headphone, 7 Color RGB LED and Microphone
              <img
                style={{ width: 100, height: 100 }}
                src={second}
                alt="Burger"
              ></img>
            </div>

            <div className="divider lg:divider-horizontal"></div>
            <div className="grid flex-grow h-54 card bg-base-300 rounded-box place-items-center">
              <b>Third Price:</b> Shopin Ston Printed Ceramic Coffee Mug 250 Ml
              White Color Coffee Then Coding
              <img
                style={{ width: 100, height: 100 }}
                src={third}
                alt="Burger"
              ></img>
            </div>
          </div>
          <br></br>

          <div className="flex min-h-full items-center justify-center  px-4 sm:px-6 lg:px-8">
            <div className="w-full max-w-md">
              <div>
                <br></br>
                <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
                  Register Here
                </h2>
              </div>
              {loggedIn ? <form
                className="mt-8 space-y-6"
                action="#"
                method="POST"
                onSubmit={onSubmit}
              >
                <input type="hidden" name="remember" defaultValue="true" />
                <div className="-space-y-px rounded-md shadow-sm">
                  {/* <div>
                    <label htmlFor="email-address" className="sr-only">
                      Name
                    </label>
                    <input
                      id="name"
                      value={name}
                      onChange={onChange}
                      name="name"
                      type="text"
                      required
                      className="relative block w-full appearance-none rounded-none rounded-t-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      placeholder="Name"
                    />
                  </div>
                  <br></br>
                  <div>
                    <label htmlFor="email-address" className="sr-only">
                      Email address
                    </label>
                    <input
                      id="email"
                      value={email}
                      onChange={onChange}
                      name="email"
                      type="email"
                      autoComplete="email"
                      required
                      className="relative block w-full appearance-none rounded-none rounded-t-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      placeholder="Email address"
                    />
                  </div> */}
                  <br></br>
                  <div>
                    <label htmlFor="phone_number" className="sr-only">
                      University/Company
                    </label>
                    <input
                      id="phone_number"
                      value={phone_number}
                      onChange={onChange}
                      name="phonenumber"
                      type="text"
                      required
                      className="relative block w-full appearance-none rounded-none rounded-b-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      placeholder="Phone Number"
                    />
                  </div>
                  <br></br>
                  <div>
                    <label htmlFor="university_or_company" className="sr-only">
                      University/Company
                    </label>
                    <input
                      id="university_or_company"
                      value={university_or_company}
                      onChange={onChange}
                      name="phonenumbeuniversity_or_companyr"
                      type="text"
                      required
                      className="relative block w-full appearance-none rounded-none rounded-b-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      placeholder="University/Company"
                    />
                  </div>
                </div>
                {registered && (
                  <>
                    <div className="alert alert-info">
                      <span className="ml-22">
                        Thank you for registering in the contest. Further
                        Information will be provided on our telegram channel.
                        Join the channel here :{" "}
                        <a href="https://t.me/+AY_GV5MEmrYxM2Vl">
                          Linked-List.co.in Telegram Channel
                        </a>
                      </span>
                    </div>
                  </>
                )}
                <div>
                  <button
                    type="submit"
                    className="group relative btn-primary flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-black hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    Register
                  </button>
                </div>
              </form>: <Link to='/sign-up'><button
                    type="submit"
                    className="group mt-8 relative btn-primary flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-black hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    Register
                  </button></Link>}
            </div>
          </div>
          <br></br>
          <p>
            Participation is open to all coders, regardless of their experience
            level. Whether you are a beginner or an expert, this contest will
            offer a challenge suited to your skills.
          </p>
          <p>
            Mark your calendars and get ready to compete! More information will
            be provided on our telegram channel. Join Here-{" "}
            <a href="https://t.me/+AY_GV5MEmrYxM2Vl">
              Linked-List.co.in Telegram Channel
            </a>
          </p>
          <p>See you at the Coding Contest!</p>
        </div>
      </div>
    </div>
    </>
  );
}

export default Contestform;

import React from 'react'
import spinner from '../assets/spinner.gif'

function CreateRoomSpinner() {
  return (
    <center><div className="w-100 mt-20 ml-66">
        <img
        width={180}
        className='text-center mx-auto'
        src={spinner}
        alt='Creating Room..'
    />
    <h2 className='text-2xl'>Creating room 😁</h2>
    </div></center>
  )
}

export default CreateRoomSpinner
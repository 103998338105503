import {BrowserRouter as Router, Routes, Route, HashRouter} from 'react-router-dom'
import Profile from './pages/Profile'
import SignIn from './pages/SignIn'
import SignUp from './pages/SignUp'
import Navbar from './components/Navbar/Navbar'
import PrivateRoute from './components/PrivateRoute'
import {ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Home from './pages/Home'
import Problems from './components/Problems'
import Discussion from './components/Discussion'
import PrivateRoute2 from './components/PrivateRoute2'
import RoomForm from './components/RoomForm'
import RoomCreate from './components/RoomCreate'
import EnterRoom from './components/EnterRoom'
import HomePage from './pages/HomePage'
import Landing from './pages/Landing'
import Contest1 from './pages/Contest1'
import Contestform from './pages/Contestform'
import ProblemOfTheDay from './pages/ProblemOfTheDay'
import RoomForm2 from './components/RoomForm2'
import PythonInterview from './pages/PythonInterview'
import ResultPage from './pages/ResultPage'
import OsInterview from './pages/OsInterview'
import DbmsInterview from './pages/DbmsInterview'
import AllRooms from './pages/AllRooms'
import Jobs from './pages/Jobs'

function App() {
  
  return (
    <>
    
      <Router>
      {/* <Navbar />  */}
      
        <Routes>
          <Route path='/' element={<Home />}/>

           {/* <Route path='/problems' element={<PrivateRoute />}>
            <Route path='/problems' element={<HomePage />}/>
          </Route> */}
          <Route path='/problems' element={<HomePage />}/>
          <Route path='/problems/*' element= {<Landing />} /> 
          
          <Route path='/profile' element={<PrivateRoute />}>
            <Route path='/profile' element={<Profile />} />
          </Route>

          <Route path='/discuss' element={<PrivateRoute2 />}>
            <Route path='/discuss' element={<Discussion />} /> 
          </Route>
          <Route path='/sign-in' element={<SignIn />} />
          <Route path='/sign-up' element={<SignUp />} />

         <Route path='/rooms/*' element= {<RoomForm />} /> 
          <Route path='/result/*' element={<ResultPage />} />
          <Route path="/allrooms" element={<AllRooms />} />
          <Route path='/jobs' element={<Jobs />} />
          {/* <Route path='/rooms/*' element={<PrivateRoute />}>
          <Route path='/rooms/*' element= {<RoomForm />} /> 
          </Route> */}

          <Route path='/roomcreate' element={<RoomCreate />} />
          <Route path='/enter-room' element={<EnterRoom />} />

          <Route path='/contest-registeration' element={<Contestform />} />
          {/* <Route path='/test' element={<Problems />} /> */}
 
          {/* <Route path='/contest205' element={<PrivateRoute />}>
            <Route path='/contest205' element={<Contest1 />} /> 
          </Route> */}
          <Route path='/contest-registration' element={<Contestform />} />
          <Route path='/problem-of-the-day' element={<ProblemOfTheDay />} />
            
          <Route path='/pythoninterview' element={<PythonInterview />}/>
          <Route path='/osinterview' element={<OsInterview/>}/>
          <Route path='/dbmsinterview' element={<DbmsInterview />}/>
        </Routes>
        
      </Router>
      
      <ToastContainer/>
    </>
  );
}

export default App;


// n = int(input())
// arr = list(map(int, input().split()))
// print(max(arr))

// Runtime error -> Failed testcase gives You have not submitted any code

import React from "react";
import { getAuth } from "firebase/auth";
import { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import Navbar from "../components/Navbar/Navbar";
import { getDatabase, ref, set, push } from "firebase/database";
import { onValue } from "firebase/database";

function Profile() {
  const auth = getAuth();
  const database = getDatabase();
  const [formData, setFormData] = useState({
    name: auth.currentUser.displayName,
    email: auth.currentUser.email,
  });
  const [solved_user, setSolved_user] = useState([]);
  const [interviews, setinterviews] = useState([]);
  useEffect(() => {
    const interview_ref = ref(
      database,
      "completed-interviews/" + auth.currentUser.uid
    );
    onValue(interview_ref, (snapshot) => {
      const data = snapshot.val();
      let interview_res = [];
      for (let d in data) {
        interview_res.push(d);
      }
      setinterviews(interview_res);
    });
  }, []);

  const { name, email } = formData;

  const navigate = useNavigate();

  const onClick = (result_id) => {
    navigate("/result/" + result_id, {
      state: { from: result_id },
    });
  };

  const onLogout = () => {
    auth.signOut();
    navigate("/");
  };

  return (
    <>
      <Navbar />
      <center>
        <div className="card w-99 bg-base-100 shadow-xl ">
          <div className="card-body">
            <p className="pageHeader text-4xl">My Profile</p>
            <h3 className="text-2xl">Name - {name ? name : email.replace("@gmail.com", "")}</h3>
            <h3 className="text-2xl">Email - {email}</h3>

            <center><button
              className="btn btn-secondary w-36 align-center"
              onClick={onLogout}
            >
              Logout
            </button></center>
          </div>
        </div>
      </center>
      <br></br>
      </>

  );
}

export default Profile;

import React from 'react'

function TestCase({od}) {
  return (
    <div>
    <table className="table w-full">
        <tbody>
            <tr>
              <td>{od.index}</td>
              <td>{od.check_status}</td>
              <td>{od.time}</td>
            </tr>
            
          </tbody>
    </table>
    </div>
  )
}

export default TestCase
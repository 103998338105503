import React from 'react'
import roomss from '../assets/Room-ss5.png'

function RoomScreenshot() {
  return (
    <center><div className="w-5/6 mb-4">
        <img
       
        className='text-center mx-auto'
        src={roomss}
    />
    </div></center>
  )
}

export default RoomScreenshot
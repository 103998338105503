/*eslint-env es6*/
import React from "react";
import { useLocation } from "react-router-dom";
import {
  getDatabase,
  ref,
  set,
  push,
  get,
  child,
  remove,
} from "firebase/database";
import { database } from "../firebase.config";
import { getAuth } from "firebase/auth";
import { useState, useEffect, useRef } from "react";
import { onValue } from "firebase/database";
import data from "../data/data.json";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { VscAccount } from "react-icons/vsc";
import axios from "axios";
// import Roomques from "../pages/Roomques";
import Timer from "../components/Timer";
import Spinner from "../components/Spinner";
import ContestTimer from "../components/ContestTimer";
import Contestques from "./Contestques";
import { useAuthStatus } from "../hooks/useAuthStatus";

function Contest1() {
  const location = useLocation();
  const auth = getAuth();
  const database = getDatabase();

  const [all_questions, set_all_questions] = useState([]);
  const [room_questions, setroom_questions] = useState([]);
  const [room_users, setroom_users] = useState([]);
  const [all_messages, setAll_messages] = useState([]);
  const [isroom_user, setisroom_user] = useState(false);
  const [data, setdata] = useState([]);
  const [show_ques, setshow_ques] = useState(false);
  const [current_ques, setcurrent_ques] = useState(0);
  const [prev_ques, setprev_ques] = useState("");
  const [timer_started, settimer_started] = useState(false);
  const [admin, setadmin] = useState(false);
  const { loggedIn, checkingStatus } = useAuthStatus();
  const [q1_solved, setq1_solved] = useState(false);
  const [q2_solved, setq2_solved] = useState(false);
  const [q3_solved, setq3_solved] = useState(false);
  const [q4_solved, setq4_solved] = useState(false);

  let submission_ref = "";
  if (loggedIn) {
    console.log("logged in: ", loggedIn);
    submission_ref = ref(
      database,
      "contest-components" + "/submissions/" + auth.currentUser.uid
    );
    console.log(submission_ref);
  }

  useEffect(() => {
    const roomRef = ref(database, "contest");
    const timerRef = ref(database, "contest-components" + "/timer");
    onValue(timerRef, (snapshot) => {
      const roomData = snapshot.val();
      if (roomData.timer_started === true) {
        settimer_started(true);
      }
    });

    const dbRef = ref(getDatabase());
    onValue(roomRef, (snapshot) => {
      const data = snapshot.val();
      let questns = []; 
      for (let d in data) {
        questns.push({
          id: data[d].id,
          title: data[d].title,
        });
      }
      setroom_questions(questns);
      // console.log(room_questions)
    });

    const submission_ref = ref(
      database,
      "contest-components" + "/submissions/" + auth.currentUser.uid
    );
    // onValue(submission_ref, (snapshot) => {
    //   const data = snapshot.val();
    //   for (let d in data) {
    //     if (data[d].id === 52 && data[d].solved === true) {
    //       setq1_solved(true);
    //     }
    //     if (data[d].id === 53 && data[d].solved === true) {
    //       setq2_solved(true);
    //     }
    //     if (data[d].id === 54 && data[d].solved === true) {
    //       setq3_solved(true);
    //     }
    //     if (data[d].id === 55 && data[d].solved === true) {
    //       setq4_solved(true);
    //     }
    //   }
    //   for (let rmq in room_questions) {
    //     if (room_questions[rmq].id === 52 && q1_solved === true) {
    //       room_questions[rmq].solved = true;
    //     }
    //     if (room_questions[rmq].id === 53 && q2_solved === true) {
    //       room_questions[rmq].solved = true;
    //     }
    //     if (room_questions[rmq].id === 54 && q3_solved === true) {
    //       room_questions[rmq].solved = true;
    //     }
    //     if (room_questions[rmq].id === 55 && q4_solved === true) {
    //       room_questions[rmq].solved = true;
    //     }
    //     if(room_questions[rmq].id === 53){
    //       console.log("line 101", room_questions[rmq])
    //       console.log("q1 solve ", q2_solved)
    //     }
        
    //   }
    // });
  }, []);

  const setques = (id) => {
    setcurrent_ques(id);
    setshow_ques(true);
  };

  const [formData, setFormData] = useState({
    message: "",
  });
  const { message } = formData;
  const navigate = useNavigate();

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  };

  return (
    <div>
      {loggedIn ? (
        <>
          <div className="flex flex-col w-full lg:flex-row">
            {
            room_questions.map((rmq) => (
              <>
             
                <div className="grid w-24 flex-grow h-24 card bg-base-300 rounded-box place-items-center ml-2 mr-2">
                  <center>
                    <b><h2 className="text-l">{rmq.title}</h2></b>
                  </center>
                  {timer_started ?(
                      <button
                        className="btn btn-sm btn-primary"
                        onClick={() => setques(rmq.id)}
                      >
                        Solve
                      </button>
                    
                  ) : (
                    <>The contest hasn't started yet!</>
                  )}
                </div>
                {/* <div className="divider lg:divider-horizontal h-12"></div> */}
              </>
            ))}
            <div className="grid w-24 flex-grow h-24 card bg-base-300 rounded-box place-items-center ml-2 mr-2">
              <ContestTimer />
            </div>
          </div>

          <div className="flex flex-row">
            <div className="w-68">
              {show_ques ? (
                // <Roomques id={current_ques} key={current_ques} isroom_user = {isroom_user} from = "" />
                <Contestques id={current_ques} key={current_ques} />
              ) : (
                <></>
              )}
            </div>
          </div>
        </>
      ) : (
        <div class="hero min-h-screen bg-base-200">
          <div class="hero-content text-center">
            <div class="max-w-md">
              <h1 class="text-4xl font-bold">
                Please Login/Sign Up to enter the contest.
              </h1>
              <br></br>
              <Link to="/sign-in">
                <button class="btn btn-primary">LogIn</button>
              </Link>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Contest1;

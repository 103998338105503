import Navbar from "../components/Navbar/Navbar";
import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnglesDown, faAnglesUp } from '@fortawesome/free-solid-svg-icons';
import { Link } from "react-router-dom";
import { getDatabase, ref } from "firebase/database";
import { onValue } from "firebase/database";

function Jobs() {
  const database = getDatabase();
  const [jobsData, setjobsData] = useState([]);
  // const jobsData = [
  //   {
  //     title: 'Software Engineer',
  //     company: 'Netflix',
  //     years: '0-2 yoe',
  //     description: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Cumque soluta iusto ipsa porro veniam accusantium fuga corrupti accusamus. At ducimus in fugiat exercitationem hic delectus earum suscipit placeat, veniam explicabo, laboriosam deleniti.'
  //   },
  //   {
  //     title: 'Frontend Developer',
  //     company: 'Google',
  //     years: '2-5 yoe',
  //     description: 'Google is looking for a Frontend Developer to join their team. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque sit amet facilisis dolor. Morbi et urna pulvinar, tincidunt nulla ut, dictum lectus.'
  //   }
  // ];

  useEffect(() => {
    const all_jobs_ref = ref(database, "jobs/");
    onValue(all_jobs_ref, (snapshot) => {
      const data = snapshot.val();
      let jobs = []
      for (let d in data) {
        jobs.push({title: data[d].title,
          company : data[d].company,
          years : data[d].years + " of experience",
          description : data[d].description,
          url: data[d].url,
          location: data[d].location
        })
        setjobsData(jobs)
        console.log(jobsData)
      }
    })
  }, [])

  const [expandedJobs, setExpandedJobs] = useState(jobsData.map(() => false));

  const toggleDescription = (index) => {
    setExpandedJobs((prev) =>
      prev.map((isExpanded, i) => (i === index ? !isExpanded : isExpanded))
    );
  };

  return (<>
    <Navbar />
    <div className="w-[90%] mx-auto my-10">
      <h1 className="mt-2 text-4xl lg:text-4xl mb-6">Recommended Jobs</h1>
      {jobsData.length > 0  && jobsData.map((job, index) => (
        <div className="w-full border rounded-md p-4 flex flex-row gap-3 mx-auto mb-4 justify-between" data-theme="halloween">
          <div
            key={index}
            className="flex flex-col"
          >
            <div className="text-md">{job.company} - {job.location} - {job.years}</div>
            <div className="text-2xl font-semibold mb-5">{job.title}</div>
            {/* <div className="text-xl mb-3">{job.company} - {job.years}</div> */}
            <div>
              {expandedJobs[index] ? (
                <>{job.description}</>
               ) : (
                 <>{job.description.slice(0, 100)}...</>
               )}
            </div>
            <button
              onClick={() => toggleDescription(index)}
              className="mt-2 underline cursor-pointer text-left"
            >
              {expandedJobs[index] ? 'Show Less' : 'Read More'}
              <FontAwesomeIcon icon={expandedJobs[index] ? faAnglesUp : faAnglesDown} className="mx-2" />
            </button>
          </div>
          <div>
            <a href={job.url} target="_blank">
              <button className="btn btn-primary">
                Apply
              </button>
            </a>
          </div>
        </div>
      ))}
    </div>
  </>
  )
}

export default Jobs
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

function EnterRoom() {
  const [formData, setFormData] = useState({
    email: "",
  });
  const { email } = formData;
  const navigate = useNavigate();

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    navigate("/rooms/" + email, { state: { room_id: email, from: email } });
  };

  return (
    <div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="w-full max-w-md space-y-8">
        <div>
          <br></br>
          <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
            Enter a Room Id
          </h2>
          <br></br>
          <p className="text-center">Find the room id at the end of room url. 
            <br></br>Eg. http://localhost:3000/rooms/207204093, Room id is 207204093.</p>
        </div>
        <form
          className="mt-8 space-y-6"
          action="#"
          method="POST"
          onSubmit={onSubmit}
        >
          <input type="hidden" name="remember" defaultValue="true" />
          <div className="-space-y-px rounded-md shadow-sm">
            <div>
              <label htmlFor="email-address" className="sr-only">
                Enter a Room Id
              </label>
              <input
                id="email"
                value={email}
                onChange={onChange}
                name="email"
                type="text"
                autoComplete="email"
                required
                className="relative block w-full appearance-none rounded-none rounded-t-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                placeholder="Room Id"
              />
            </div>
          </div>

          <div>
            <button
              type="submit"
              className="group relative flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              Enter Room
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default EnterRoom;
